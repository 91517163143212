import * as React from "react";
import PageLayout from "../components/PageLayout";
import { StaticImage } from "gatsby-plugin-image";

// markup
const WhaleBombs = () => {
  return (
    <PageLayout>
      <div className="center">
        <div className="content">
          <h1 className="title">Whale Adam Bombs (Unofficial)</h1>
          <div className="image">
            <StaticImage src="../images/whale-adam-bombs.jpg" alt="Whale Adam Bombs" layout="constrained" width={400} />
          </div>
          <div className="description">
            <p>Created in 2021, Whale Adam is a community created icon to celebrate and reward those who had conviction in the vision of Adam Bomb Squad, especially through its darkest days post-meta. It is given as a gift to those who hold more than 30 Adam Bomb Squad NFTs - as a declaration of their future 'whale' status.</p>
          </div>
        </div>
      </div>
      <hashku-mint api-url="https://api.hashku.com/api" team-slug="whale-adam-bombs-unofficial" project-slug="main" contract-network="1" contract-address="0x607A6d31c55de70d6635541BB94d5eCe0061681b" eligible-check="yes" infura-id="3ed982906a2d473f8918f0730b2ea194"></hashku-mint>
    </PageLayout>
  );
};

export default WhaleBombs;
